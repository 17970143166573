import axios from "axios";
import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { mainUrl } from "../constants";

import { useParams } from "react-router-dom";
var bloodGroupList = [];
const MunicipalityVdcs = ({ selectedMunicipality }) => {
  const [selectedWard, setSelectedWard] = useState("");
  const [selectedBloodGroup, setSelectedBloodGroup] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [quickContacts, setQuickContacts] = useState([]);
  const [wardInfo, setWardInfo] = useState([]);

  const params = useParams();

  useEffect(() => {
    const userCookie = new Cookies();
    const accessToken = userCookie.get("access");
    axios
      .get(`${mainUrl}/store/people/?municipality_name=` + params.name, {
        headers: {
          Authorization: `BB ` + accessToken,
        },
      })
      .then((response) => {
        // fetch recent donors and add recent flag
        axios
          .get(`${mainUrl}/store/people/recent_donors/`)
          .then((res) => {
            if (res.status === 200) {
              const recentDonors = res.data;

              const updatedData = response.data.map((item) => {
                const isNewData = recentDonors.some(
                  (newItem) => newItem.id === item.id
                );
                return {
                  ...item,
                  recent: isNewData ? true : false,
                };
              });

              setData(updatedData);
            }
          })
          .catch((err) => {
            console.log(err);
          });

        bloodGroupList = [
          ...new Set(response.data.map((data) => data.bloodGroup)),
        ];
        bloodGroupList.unshift("All");

        // When all data for selected municipality is fetched, fetch all the wards of selected municipality
        axios
          .get(`${mainUrl}/store/wards/?search=` + params.name)
          .then((response) => {
            console.log(response);
            setWardInfo(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //quick contacts
  useEffect(() => {
    if (params.id) {
      axios
        .get(`${mainUrl}/store/municipalities/${params.id}/quick-contacts/`)
        .then((response) => {
          setQuickContacts(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [params]);

  //handle changes here
  const handleWardChange = (event) => {
    setSelectedWard(event.target.value);
    setCurrentPage(1);
  };
  const handleBloodGroupChange = (event) => {
    setSelectedBloodGroup(event.target.value);
    setCurrentPage(1);
  };
  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset current page when search query changes
  };
  const filteredData = data.filter((item) => {
    if (
      (selectedWard === "" || selectedWard === "- Select Ward -") &&
      (selectedBloodGroup === "" || selectedBloodGroup === "All") &&
      searchQuery === ""
    ) {
      return true;
    } else {
      // return (
      //   (selectedWard === "" ||
      //     item.ward.slice(-2, -1) === selectedWard.slice(-2, -1)) &&
      //   (selectedBloodGroup === "" ||
      //     selectedBloodGroup === "All" ||
      //     item.bloodGroup === selectedBloodGroup) &&
      //   (searchQuery === "" ||
      //     item.bloodGroup.toLowerCase().includes(searchQuery.toLowerCase()))
      // );

      const selectedWardNo = selectedWard.match(/\d+/g);
      return (
        (selectedWard === "" ||
          item.permanent_address.ward == selectedWardNo[0]) &&
        (selectedBloodGroup === "" ||
          selectedBloodGroup === "All" ||
          item.bloodGroup === selectedBloodGroup) &&
        (searchQuery === "" ||
          item.bloodGroup.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    }
  });

  //paginations
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    console.log("Fetching data for page", currentPage);
  }, [currentPage]);

  console.log("data", data);

  return (
    <section className="px-4 mb-4">
      <section className="">
        <div className="">
          <div className="flex flex-col space-y-4 md:flex-row md:items-center md:space-x-4 md:space-y-0 mb-4">
            {/* ... (Existing code) */}
          </div>
        </div>
        {/* ... (Remaining code) */}
      </section>
      <div className="">
        <div className="flex flex-col space-y-4 md:flex-row md:items-center md:space-x-4 md:space-y-0 mb-4">
          <div className=" ">
            {/* relative */}
            {/* Add search input */}
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search by Blood Group..."
              className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-2 focus:border-indigo-500 transition-colors duration-300 
      sm:w-32 md:w-48 lg:w-64 xl:w-80  "
            />
          </div>

          <div className="">
            <select
              value={selectedWard}
              onChange={handleWardChange}
              className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-2 focus:border-indigo-500"
            >
              <option> - Select Ward -</option>
              {wardInfo.map((item, index) => {
                // return <option key={index}>{item.title}</option>;
                return (
                  <option key={index}>
                    {item.name !== null ? item.name : ""}
                    {item.name !== null ? " (" : ""}
                    {item.ward_number}
                    {item.name !== null ? ")" : ""}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="">
            <select
              value={selectedBloodGroup}
              onChange={handleBloodGroupChange}
              className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-2 focus:border-indigo-500"
            >
              <option> - Blood Group -</option>
              {bloodGroupList.map((item, index) => {
                return <option key={index}>{item}</option>;
              })}
            </select>
          </div>
          <div className="">
            <select
              // value={selectedBloodGroup}
              // onChange={handleBloodGroupChange}
              className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-2 focus:border-indigo-500"
            >
              <option> - Quick Contact -</option>
              {/* <option>1:Mayor: 9812767056 / Mayor: 9812767056</option>
              <option>+977 9812767056</option>
              <option>+977 9812767056</option> */}
              {quickContacts.map((contact) => {
                return (
                  <option key={contact.id}>
                    {`${contact.name}: ${contact.contact}`}{" "}
                  </option>
                );
              })}

              {/* ... (remaining quick contact options) */}
            </select>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto bg-gray-100 ">
        <table className="w-full h-full border-collapse text-center ">
          <thead className="thead bg-indigo-500 text-white">
            <tr>
              <th className="py-2 px-4 text-center md:sticky md:top-0 md:bg-indigo-500 md:text-white text-sm md:text-base">
                SN
              </th>
              <th className="py-2 px-4 text-center md:sticky md:top-0 md:bg-indigo-500 md:text-white text-sm md:text-base">
                Name
              </th>
              <th className="py-2 px-4 text-center md:sticky md:top-0 md:bg-indigo-500 md:text-white text-sm md:text-base">
                Age
              </th>
              <th className="py-2 px-4 text-center md:sticky md:top-0 md:bg-indigo-500 md:text-white text-sm md:text-base">
                Blood group
              </th>
              <th className="py-2 px-4 text-center md:sticky md:top-0 md:bg-indigo-500 md:text-white text-sm md:text-base">
                Contact
              </th>
              <th className="py-2 px-4 text-center md:sticky md:top-0 md:bg-indigo-500 md:text-white text-sm md:text-base">
                Ward No
              </th>
              <th className="py-2 px-4 text-center md:sticky md:top-0 md:bg-indigo-500 md:text-white text-sm md:text-base">
                Tole/ Village
              </th>
              <th className="py-2 px-4 text-center md:sticky md:top-0 md:bg-indigo-500 md:text-white text-sm md:text-base">
                Current Address
              </th>
              <th className="py-2 px-4 text-center md:sticky md:top-0 md:bg-indigo-500 md:text-white text-sm md:text-base">
                Workplace
              </th>
              <th className="py-2 px-4 text-center md:sticky md:top-0 md:bg-indigo-500 md:text-white text-sm md:text-base">
                Profession
              </th>
            </tr>
          </thead>
          <tbody className="tbody divide-y divide-gray-200">
            {currentItems.map((item, index) => {
              const itemIndex = indexOfFirstItem + index + 1;
              return (
                <tr
                  key={index}
                  style={{
                    color: item.recent ? "#ff3333" : "inherit",
                  }}
                >
                  <td className="py-2 px-4 text-center md:sticky md:left-0 ">
                    {itemIndex}
                  </td>
                  <td className="py-2 px-4 text-center md:sticky md:left-0 ">
                    {/* {item.name} */}
                    {item.first_name + " " + item.last_name}
                  </td>
                  <td className="py-2 px-4 text-center md:sticky md:left-0 ">
                    {item.age}
                  </td>
                  <td className="py-2 px-4 text-center md:sticky md:left-0 ">
                    {item.bloodGroup}
                  </td>
                  <td className="py-2 px-4 text-center md:sticky md:left-0 ">
                    {item.contact}
                  </td>
                  <td className="py-2 px-4 text-center md:sticky md:left-0 ">
                    {/* {item.ward} */}
                    {item.permanent_address.ward}
                  </td>
                  <td className="py-2 px-4 text-center md:sticky md:left-0 ">
                    {/* {item.tole} */}
                    {item.permanent_address.tole}
                  </td>
                  <td className="py-2 px-4 text-center md:sticky md:left-0 ">

                    {item.current_address}
                    {/* {item.current_address.municipality +
                      ", " +
                      "( " +
                      item.current_address.local_name +
                      " )"} */}
                  </td>

                  <td className="py-2 px-4 text-center md:sticky md:left-0 ">
                    {item.workplace}
                  </td>
                  <td className="py-2 px-4 text-center md:sticky md:left-0 ">
                    {item.profession}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {filteredData.length > itemsPerPage && (
        <ul className="flex  items-center justify-end  mt-4 md:mt-0 md:space-x-4 lg:space-x-0">
          <li>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-2 py-2 border border-gray-300 rounded-md disabled:opacity-50 transition-colors duration-300 focus:outline-none hover:bg-gray-100"
            >
              <FaAngleLeft />
            </button>
          </li>
          {Array.from({ length: totalPages }, (_, index) => index + 1).map(
            (page) => (
              <li key={page}>
                <button
                  className={`px-4 py-2 border border-gray-300 rounded-md ${page === currentPage
                      ? "bg-indigo-500 text-white"
                      : "hover:bg-gray-100"
                    } transition-colors duration-300 focus:outline-none`}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              </li>
            )
          )}
          <li>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-2 py-2 border border-gray-100 rounded-md disabled:opacity-50 transition-colors duration-300 focus:outline-none hover:bg-gray-100"
            >
              <FaAngleRight />
            </button>
          </li>
        </ul>
      )}
    </section>
  );
};

export default MunicipalityVdcs;
