import axios from "axios";
import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";


import { mainUrl } from "../constants";

const link = "/municipalityvdc";

const BloodDoners = () => {
  const [selectedMunicipality, setSelectedMunicipality] = useState("");
  // const [selectedVDC, setSelectedVDC] = useState("");
  const [selectedHospital, setSelectedHospital] = useState("");
  const [selectedEmergencyDonor, setSelectedEmergencyDonor] = useState("");
  const [selectedPatners, setSelectedPatners] = useState("");
  const [selectedRecentdonors, setSelectedRecentdonors] = useState("");
  const [openSection, setOpenSection] = useState(null);

  const [minicipalities, setMunicipalities] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [emergencyDonors, setEmergencyDonors] = useState([]);
  const [associateDonors, setAssociateDonors] = useState([]);
  const navigate = useNavigate();

  const handleMunicipalityChange = (option) => {
    setSelectedMunicipality(option);
    console.log(option);

    // navigate(`/municipalityvdc/${option.name.toLowerCase()}/${option.id}`);
    // navigate(`/municipalityvdc/${option.name.toLowerCase()}/${option.id}`);
  };

  // const handleVDCChange = (option) => {
  //   setSelectedVDC(option);
  // };

  const handleHospitalChange = (option) => {
    setSelectedHospital(option);
  };

  const handleEmergencyChange = (option) => {
    setSelectedEmergencyDonor(option);
  };

  const handlePatnersChange = (option) => {
    setSelectedPatners(option);
  };
  const handleRecentdonorsChange = (option) => {
    setSelectedRecentdonors(option);
  };
  useEffect(() => {
    axios
      .get(`${mainUrl}/store/municipalities/`)
      .then((response) => {
        const newRes = response.data.map((item) => ({
          ...item,
          link: "/municipalityvdc/" + item.name + "/" + item.id,
        }));
        setMunicipalities(newRes);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const userCookie = new Cookies();
    const accessToken = userCookie.get("access");
    axios
      .get(`${mainUrl}/store/associate-hospitals/`,{        headers: {
        Authorization: `BB ` + accessToken,
      },})
      .then((response) => {
        // setData(
        //   response.data.map((data) => {
        //     return { name: data.name, link: "/" + data.name.toLowerCase() };
        //   })
        const newRes = response.data.map((item) => ({
          ...item,
          link: "/hospitaldonor/" + item.id,
        }));
        setHospitals(newRes);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const userCookie = new Cookies();
    const accessToken = userCookie.get("access");
    axios
      .get(`${mainUrl}/store/emergency-donors/`,
        {
          headers: {
            Authorization: `BB ` + accessToken,
          },
        }
      )
      .then((response) => {
        const newRes = response.data.map((item) => ({
          ...item,
          link: "/emergencydonor/" + item.id,
        }));
        // console.log(newRes);
        setEmergencyDonors(newRes);
        // console.log(emergencyDonors);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const userCookie = new Cookies();
    const accessToken = userCookie.get("access");
    axios
      .get(`${mainUrl}/store/associates/`,{
        headers: {
          Authorization: `BB ` + accessToken,
        },}
      )
      .then((response) => {
        // setData(
        //   response.data.map((data) => {
        //     return { name: data.name, link: "/" + data.name.toLowerCase() };
        //   })
        const newRes = response.data.map((item) => ({
          ...item,
          link: "/associatedonergroup/" + item.id,
        }));
        setAssociateDonors(newRes);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const Dropdown = ({ title, options, selectedOption, onSelect }) => {
    const toggleDropdown = () => {
      setOpenSection(openSection === title ? null : title);
    };

    return (
      <div className="w-full md:w-64 dropdown-container">
        <label htmlFor={title.toLowerCase()} className="block font-bold mb-2">
          {title}:
        </label>
        <div className=" ">
          <div
            onClick={toggleDropdown}
            className="block appearance-none w-full border border-gray-300 rounded px-4 py-2 focus:outline-none cursor-pointer"
          >
            {selectedOption ? (
              <Link
                to={`/municipalityvdc/${selectedOption.name}`}
                className="text-blue-600"
              >
                {selectedOption.name}
              </Link>
            ) : (
              `Select ${title}`
            )}
            <span className="ml-1">&#9660;</span>
          </div>
          {openSection === title && (
            <ul className="dropdown-content bg-gray-100 border border-gray-300 rounded mt-1 py-1 ">
              {options.map((option, index) => (
                <li
                  key={index}
                  // onClick={() => handleOptionSelect(option)}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                >
                  <p
                    onClick={() => navigate(option.link)}
                    className="text-blue-6000"
                  >
                    {option.name}
                  </p>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col md:flex-row justify-center  gap-4 py-8 mx-4">
      <Dropdown
        title="Municipality/VDCs"
        options={minicipalities}
        selectedOption={{ name: selectedMunicipality, link: null }}
        onSelect={handleMunicipalityChange}
      />

      <Dropdown
        title="Hospital"
        options={hospitals}
        selectedOption={{ name: selectedHospital, link: null }}
        onSelect={handleHospitalChange}
      />

      <Dropdown
        title="Emergency Donors"
        options={[...emergencyDonors].map((item) => ({
          ...item,
          name: item.profession,
        }))}
        selectedOption={{ name: selectedEmergencyDonor, link: null }}
        onSelect={handleEmergencyChange}
      />

      <Dropdown
        title="Associate Volunteer"
        options={associateDonors}
        selectedOption={{ name: selectedPatners, link: null }}
        onSelect={handleHospitalChange}
      />
      <Dropdown
        title="Recent Donors /Volunteers"
        options={[
          { name: "Recent Donors", link: "/recentdonors" },
          {
            name: "Donors till Date",
            link: "/donortilldate",
          },
          {
            name: "Volunteers",
            link: "/volunteer",
          },
        ]}
        selectedOption={{ name: selectedPatners, link: null }}
        onSelect={handleRecentdonorsChange}
      />
    </div>
  );
};

export default BloodDoners;
